export const basicColor = {
  /** 主题色 */
  primary: '#00BB8F',
  primary50: '#00BB8F',
  primary10: '#00BB8F1A',
  primary20: '#03D0A6',
  primaryFont: '#C3F0E8',
  background: '#00BB8FFF',
  /** 渐变主题色 */
  primaryLinearGradient: ['#00BB8F', '#FFFFFF'],
  primaryGrayLinearGradient: ['#00BB8F', '#FFFFFF', '#F5F5F5', '#F5F5F5'],
  proxyLinearGradient: ['#dbeeea', '#dbeeea'],
  proxybgColor: '#C92619',
  proxyResetLink: '#0f8d74',
  proxylightLinear: ['#E51251', '#FF8A00'],
  loginLinearGradient: ['#F9F0FF', '#FFF'],
  rechargeButtonLinearGradient: ['#e51251', '#ff8a00'],
  invitationRecordLinearGradient: ['#FBCBCB', 'rgba(255, 255, 255, 0.00)'],

  border: '#DADADA',
  borderShallow: 'rgba(116,116,116,0.13)',
  brown: '#8F4E00',
  red: 'red',
  white: '#fff',
  dark: '#000',
  transparent: '#0000',
  invitationRuleColors: {
    tableHeader: '#0f8d74',
    tableEven: 'rgba(15,141,116,0.5)',
    tableOdd: 'rgba(15,141,116,0.3)',
  },
};

export const fontColor = {
  main: '#333333',
  main70: 'rgba(36,36,36,0.7)',
  second: '#31373D',
  accent: '#5F6975',
  secAccent: '#9FA5AC',
  //中奖状态 背景渐变色
  wonFontColor: '#8F4E00',
  //未中奖状态 背景渐变色
  noWinFontColor: '#242424',
  //未开奖状态 背景渐变色
  toDrawnFontColor: '#0f8d74',
  primary: '#00BB8F',
  grey: '#D8E0EA',
  white: '#ffffff',
  brown: '#8F4E00',
  button: '#8F4E00',
};

export const backgroundColor = {
  /** 蓝 */
  main: '#00BB8F',
  /** 橙 */
  second: '#F15802',
  /** 红 */
  accent: '#F44336',
  /** 绿 */
  secAccent: '#009919',
  grey: '#D8E0EA',
  white: '#ffffff',
  /** 浅灰色,用于图片加载前的颜色 */
  palegrey: '#E8EBEE',
  lightGrey: '#F5F5F5',
  //home-menu
  chestLinearGradient: ['#FCDC3623', '#ffffff'],
  //中奖状态 背景渐变色
  wonLinearGradient: ['#efe276', '#efe27600'],
  //未中奖状态 背景渐变色
  noWinLinearGradient: ['#e8e8e8', '#e8e8e800'],
  //未开奖状态 背景渐变色
  toDrawnLinearGradient: ['#bef1e7', '#bef1e700'],
  blue: '#1776FF',
  blueGrey: '#B0AEA0',
  palegreyNext: '#F6F7FA',
};

export const fontSize = {
  xxs: 8,
  xs: 10,
  s: 12,
  m: 14,
  l: 16,
  xl: 20,
};

export const paddingSize = {
  zorro: 0,
  xxxs: 2,
  xxs: 4,
  xs: 6,
  s: 8,
  m: 10,
  l: 12,
  xl: 14,
  xxl: 16,
  xxxl: 18,
};

export const borderRadiusSize = {
  xxs: 2,
  xs: 4,
  s: 6,
  m: 8,
  l: 10,
  xl: 12,
  xxl: 14,
  xxxl: 16,
};

export const iconSize = {
  xxs: 10,
  xs: 12,
  s: 16,
  m: 20,
  l: 24,
  xl: 28,
  xxl: 32,
};

export const imageSize = {
  xs: 36,
  s: 48,
  m: 64,
  l: 72,
};

export const invitationBaseColors = {
  signGiftBgColor: basicColor.primary,
  registSuccess: basicColor.primary,
};
